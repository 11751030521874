//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiHome } from "@/api/apiHome";
import env from "@/../env.js";
import cookie from "../../../../plugins/storage/cookies";
import comSmallTMBSke from "../skeComponents/comSmallTMBSke.vue";
import comTabSke from "../skeComponents/comTabSke.vue";
import comTeamTMBSke from "../skeComponents/comTeamTMBSke.vue";
import NoData from "../noData";
import TabList from "@/components/TabList/index.vue";
import WorkerTeamCell from "./itemCell/WorkerTeamCell";
import HireLaborCell from "./itemCell/HireLaborCell";
import JobOfferCell from "./itemCell/JobOfferCell";
import LookWorkerCell from "./itemCell/LookWorkerCell";
export default {
  name: "",
  components: {
    NoData,
    TabList,
    WorkerTeamCell,
    HireLaborCell,
    JobOfferCell,
    LookWorkerCell,
    SubcontractCell: () => import("./itemCell/SubcontractCell"),
    FooterLoad: () => import("@/components/FooterLoad/index.vue"),
    comSmallTMBSke,
    comTabSke,
    comTeamTMBSke
  },
  props: {
    activeIndex: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      list: [],
      load: true,
      isLoadData: false,
      isClose: false,
      isMore: false,
      isEmpty: false,
      currentPage: 1,
      menuList: [],
      firstType: "" // 一级菜单
    };
  },
  computed: {},
  watch: {
    activeIndex: {
      handler: function() {
        this.menuList = [];
        this.firstType = "";
        this.refreshData();
      },
      immediate: false
    }
  },
  async fetch() {
    try {
      let res = await apiHome.sysTeamCategory();
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list =
          data.map(item => {
            return {
              ...item,
              name: item.name,
              value: item.no
            };
          }) || [];
        this.menuList = [{ name: "全部", value: "" }, ...list];
      }
    } catch (error) {}
    // try {
    //   const parmas = {
    //     areacode: "",
    //     // isCompany: null,
    //     limit: 6,
    //     page: this.currentPage,
    //     teamType: this.activeIndex
    //     // teamSize: "",
    //     // teamStatus: null,
    //   };
    //   let res = await apiHome.getNeweSourseList(parmas);
    //   const { data, code, msg } = res.data;
    //   if (+code === 200) {
    //     const list = data.records || [];
    //     this.isLoadData = list.length === parmas.limit;
    //     this.list = this.list.concat(list);
    //     this.isEmpty = this.list.length === 0;
    //     this.isMore = this.currentPage < data.pages;
    //     this.load = false;
    //   }
    // } catch (error) {}
  },
  created() {},
  mounted() {
    this.getTeamList();
  },
  methods: {
    // 刷新
    refreshData() {
      this.isEmpty = false;
      this.isClose = false;
      this.currentPage = 1;
      this.list = [];
      this.initList();
    },
    // 加载
    loadData() {
      if (this.isLoadData) {
        this.currentPage += 1;
        this.initList();
        this.isClose = true;
        return;
      }
    },
    // 初始化列表数据
    initList() {
      const index = this.activeIndex;
      const isFirstMenu = this.menuList.length;
      switch (index) {
        case 0: // 施工队
          !isFirstMenu && this.sysTeamCategory();
          this.getTeamList();
          break;
        case 1: // 班组
          !isFirstMenu && this.sysTeamCategory();
          this.getTeamList();
          break;
        case 2: // 工程公司
          // !isFirstMenu && this.getTeamSize();
          this.getComList();
          break;
        case 3: // 临时用工
          !isFirstMenu && this.getTypeList();
          this.getLaborList();
          break;
        case 4: // 工种报价
          !isFirstMenu && this.sysTeamCategory();
          this.getTeamPriceList();
          break;
        case 5: // 劳务用工
          !isFirstMenu && this.getNoticeMenu();
          this.getRecommendEngineer();
          break;
        default:
          // 找工人
          !isFirstMenu && this.sysTeamFindWorder();
          this.getRecommendWorker();
          break;
      }
    },
    // 点击跳转详情
    todetail(item) {
      const companyStoreUrl = env[process.env.environment].VUE_APP_MATERIALSURL;
      const gongduiUrl = env[process.env.environment].VUE_APP_TEAM_URL;
      if (+this.activeIndex === 1 || +this.activeIndex === 0) {
        // 班组
        const { companyNo, userNo, no } = item;
        let query = {};
        console.log("banzu", item);
        // return
        if (item.isCompany === 1 && item.teamType === 0) {
          window.open(
            `${gongduiUrl}/ConstructionTeamDetail?companyNo=${companyNo}&teamNo=${no}`
          );
        } else {
          window.open(
            `${gongduiUrl}/ConstructionTeamDetail?userNo=${userNo}&teamNo=${no}`
          );
        }
      }
      if (+this.activeIndex === 2) {
        const { companyNo } = item;
        window.open(`${companyStoreUrl}/company?no=${companyNo}&type=63`);
        return;
      }
      if (+this.activeIndex === 3) {
        // 招工用工
        const { no } = item;
        window.open(`${gongduiUrl}/FlexibleDetail?no=${no}`);
        return;
      }
      if (+this.activeIndex === 4) {
        // 工种报价
        const { companyNo, no, userNo } = item;
        if ((companyNo && companyNo !== 0) || (companyNo && userNo)) {
          window.open(
            `${gongduiUrl}/TeamOfferDetail?companyNo=${companyNo}&pNo=${no}`
          );
        } else if (userNo) {
          window.open(
            `${gongduiUrl}/IndividualTeamOfferDetail?userNo=${userNo}&pNo=${no}`
          );
        }
        return false;
      }
      if (+this.activeIndex === 5) {
        // 劳务用工
        const { no } = item;
        // https://gongdui.gcw.net/labourService?no=1417011683452391424
        window.open(`${gongduiUrl}/labourService?no=${no}`);
        return;
      }
      if (this.activeIndex === 6) {
        // 找工人
        const { no, categoryNo, industryNo } = item;
        window.open(
          `${gongduiUrl}/workerDetail?no=${no}&industryNo=${industryNo}&categoryNo=${categoryNo}`
        );
      }
    },
    // 点击一级菜单切换数据
    clickTabItem(item) {
      const { name, value } = item;
      this.firstType = value;
      this.refreshData();
    },
    // 劳务分包菜单
    async getNoticeMenu() {
      let res = await apiHome.getlistChild();
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list =
          data.map(item => {
            return {
              ...item,
              name: item.name,
              value: item.no
            };
          }) || [];
        this.menuList = [{ name: "全部", value: "" }, ...list];
        return;
      }
    },
    // 劳务分包列表
    async getRecommendEngineer() {
      const area = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : null;
      this.load = true;
      const parmas = {
        page: this.currentPage,
        industryNo: this.firstType,
        limit: 6,
        provinceNo: area.id || ''
      };
      let res = await apiHome.laborEmploymentList(parmas);
      const { data, code, msg, pages = 0 } = res.data;
      if (+code === 200) {
        const list = data.records || [];
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.load = false;
        return;
      }
      this.load = false;
    },
    // 招工用工列表
    async getRecommendWorker() {
      const area = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : null;
      this.load = true;
      const parmas = {
        areacode: "",
        pageSize: 6,
        industryNo: this.firstType,
        pageNum: this.currentPage,
        provinceNo: area.id
      };
      let res = await apiHome.getRecommendWorker(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list = data.records || [];
        this.isLoadData = list.length === parmas.pageSize;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.load = false;
        return;
      }
      this.load = false;
    },
    // 招工用工列表
    async getTeamPriceList() {
      this.load = true;
      const area = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : null;
      const parmas = {
        areacode: "",
        limit: 6,
        page: this.currentPage,
        firstCategoryNo: this.firstType,
        provinceNo: area.id
      };
      let res = await apiHome.getTeamPriceList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list = data.records || [];
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.total;
        this.load = false;
        return;
      }
      this.load = false;
    },
    async sysTeamFindWorder() {
      let res = await apiHome.sysTeamFindWorder();
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list =
          data.map(item => {
            return {
              ...item,
              name: item.name,
              value: item.no
            };
          }) || [];
        this.menuList = [{ name: "全部", value: "" }, ...list];
        return;
      }
    },
    // 工种报价-一级分裂
    async sysTeamCategory() {
      let res = await apiHome.sysTeamCategory();
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list =
          data.map(item => {
            return {
              ...item,
              name: item.name,
              value: item.no
            };
          }) || [];
        this.menuList = [{ name: "全部", value: "" }, ...list];
        return;
      }
    },
    // 招工用工列表
    async getLaborList() {
      this.load = true;
      const area = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : null;
      const parmas = {
        areacode: "",
        limit: 6,
        page: this.currentPage,
        industryNo: this.firstType,
        provinceNo: area.id || "",
        latitude: area.lat || "",
        longitude: area.lng || "",
        timeSortType: 1,
        sortType: 1
      };
      let res = await apiHome.getLaborList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list = data.records || [];
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.load = false;
        return;
      }
      this.load = false;
    },
    // 临时用工-一级分类
    async getTypeList() {
      let res = await apiHome.GetClassification();
      const { data, code, msg } = res.data;
      if (+code == 200) {
        const list =
          data.map(item => {
            return {
              ...item,
              name: item.name,
              value: item.no
            };
          }) || [];
        this.menuList = [{ name: "全部", value: "" }, ...list];
        return;
      }

      // let res = await apiHome.getTypeList();
      // const { data, code, msg } = res.data;
      // if (+code === 200) {
      //   const list =
      //     data.category.map(item => {
      //       return {
      //         ...item,
      //         name: item.name,
      //         value: item.no
      //       };
      //     }) || [];
      //   this.menuList = [{ name: "全部", value: "" }, ...list];
      //   return;
      // }
    },
    async getTeamSize() {
      let res = await apiHome.getTeamSize();
      console.log("res", res);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list =
          data.map(item => {
            return {
              ...item,
              name: item.ranges,
              value: item.no
            };
          }) || [];
        this.menuList = [{ name: "全部", value: "" }, ...list];
        return;
      }
    },
    async getComList() {
      this.load = true;
      const area = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : null;
      let teamNumber =
        this.firstType === ""
          ? ""
          : this.menuList.find(item => item.no == this.firstType).name || "";
      console.log('env[process.env.environment].VUE_APP_AUTHURLTYPE', env[process.env.environment].MODE)
      let isdevelopment =  env[process.env.environment].MODE === 'development'
      const parmas = {
        areaNoList: [],
        categoryNo: "",
        cityNo: "",
        hasMore: true,
        limit: 6,
        page: this.currentPage,
        provinceNo: area ? area.id : 650000,
        teamNumber: teamNumber,
        profile: isdevelopment ? "dev" : "pro"
      };
      let res = await apiHome.getComList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list = data.records || [];
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.load = false;
        return;
      }
      this.load = false;
    },
    // 施工队、班组列表
    async getTeamList() {
      this.load = true;
      const area = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : null;
      const parmas = {
        areacode: "",
        limit: 6,
        page: this.currentPage,
        teamType: this.activeIndex,
        firstCategoryNo: this.firstType,
        provinceNo: area.id || ""
      };
      let res = await apiHome.getNeweSourseList(parmas);
      const { data, code, msg } = res.data;
      if (+code === 200) {
        const list = data.records || [];
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < data.pages;
        this.load = false;
        return;
      }
      this.load = false;
    }
  }
};
