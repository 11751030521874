// 工种报价方案枚举
export const materialTypeEnum = {
    0:'包含辅材',
    1:'包含设备',
    2:'包含辅材和设备',
    3:'不包含辅材和设备',
}
// 招标类型枚举
export const bidTypeEnum = {
    1: '机械租赁',
    2: '劳务分包',
    3: '商品/材料',
    4: '专业工程',
    5: '设备租赁',
    6: '勘察设计',
    7: '建筑设计',
    8: '工程监理',
    9: '工程造价咨询',
    10: '工程总承包',
    11: '房产租赁',
    12: '车辆租赁'
}
// 招标方式枚举
export const verticalTypeEnum = {
    1: '资格预审',
    2: '资格后审',
    3: '报价即报名',
}
// 招标方式
export const biddingMethodEnum = {
    1:'公开招标',
    2:'邀请招标'
}
// 招标状态
export const stateEnum = {
    1: {name:'待招标', className: 'state-tender', timeLabel: '投标开始时间：', timeField: 'startTime'},
    2: {name:'招标中', className: 'state-stay', timeLabel: '投标结束时间：', timeField: 'endTime'},
    3: {name:'评标中', className: 'state-stay', timeLabel: '开标时间：', timeField: 'bidTime'},
    4: {name:'招标结束', className: 'state-end', timeLabel: '定标时间：', timeField: 'winningTime'},
    6: {name:'招标中', className: 'state-stay', timeLabel: '投标结束时间：', timeField: 'endTime'},
    8: {name:'待报名', className: 'state-stay', timeLabel: '报名开始时间：', timeField: 'startTime'},
    9: {name:'报名中', className: '', timeLabel: '报名结束时间：', timeField: 'applyEndTime'},
    // 以下状态后台数据会过滤掉
    5: {name:'废标', className: '', timeLabel: ''},
    7: {name:'流标', className: '', timeLabel: ''},
    10: {name:'资格审查中', className: '', timeLabel: ''},
    20: {name:'审批中', className: '', timeLabel: ''},
    21: {name:'审批不通过', className: '', timeLabel: ''},
}