//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiHome } from "@/api/apiHome";
import List from "./List.vue";
export default {
  components: {
    CommonTag: () => import("../commonTag"), //切换tag
    List // 工队列表
  },
  data: function() {
    return {
      activeIndex: 0,
      list: []
    };
  },
  async asyncData({ params, error }) {
    // return {};
  },
  methods: {
    changeTag(index) {
      this.activeIndex = index;
    }
  },
  created() {
    this.changeTag(0);
  },
  mounted() {}
};
