var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"goods"},[_c('div',{attrs:{"id":"teamBack"}}),_vm._v(" "),_c('common-tag',{attrs:{"title":'工队服务',"tagList":[
      '施工队',
      '班组',
      '工程公司',
      '临时用工',
      '工种报价',
      '劳务用工',
      '专业工人'
    ],"activeIndex":_vm.activeIndex},on:{"changeTag":_vm.changeTag}}),_vm._v(" "),_c('List',{attrs:{"activeIndex":_vm.activeIndex}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }