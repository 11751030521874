//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imageCutting } from "@/util/util.js";
export default {
  name: "",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    activeIndex: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      defaultLogo: {
        0: require("@/assets/shigongdui_icon.png"),
        1: require("@/assets/zygongdui_icon.png"),
        2: require("@/assets/qiyedianpu.png")
      }
    };
  },
  computed: {
    teamAddres() {
      if (this.activeIndex == 0) {
        return "工队所在地 : ";
      } else if (this.activeIndex == 1) {
        return "班组所在地 : ";
      } else {
        return "";
      }
    },
    workState() {
      if (this.item.buildStatus != null && +this.item.buildStatus === 0) {
        // 施工中
        return require("@/assets/gongdui/working.png");
      } else if (this.item && +this.item.buildStatus === 1) {
        // 可以出工
        return require("@/assets/gongdui/canwork.png");
      } else {
        return "";
      }
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    imageCutting(e) {
      let url = Array.prototype.slice.apply(arguments)[0];
      if (url && url.indexOf("https") > -1) {
        return imageCutting(...arguments);
      } else {
        return this.defaultLogo[this.activeIndex];
      }
    }
  },
  filters: {
    filterRep(data) {
      if (!data) return "";
      let reg = new RegExp(",|、", "g");
      // return data.replace(/,|、/g, '｜')
      return data.replace(reg, "｜");
    },
    /**
     * 大数值转换为万，亿函数
     * @param {Number} 大数
     * @param {Number} 保留几位小数
     */
    numConversion(num, point = 2) {
      if (!num) return "";
      const numStr = num.toString().split(".")[0]; // 去掉小数点后的数值字符串
      const numLen = numStr.length;
      if (numLen < 6) {
        return numStr;
      } else if (numLen >= 6 && numLen <= 8) {
        const decimal = numStr.substring(numLen - 4, numLen - 4 + point);
        const res = parseInt(num / 10000) + "." + decimal + "万";
        return res;
      } else if (numLen > 8) {
        const decimal = numStr.substring(numLen - 8, numLen - 8 + point);
        const res = parseInt(num / 100000000) + "." + decimal + "亿";
        return res;
      }
    },
    datanumber2datetime(datetime, fmtdata) {
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    }
  }
};
