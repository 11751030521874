//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { materialTypeEnum } from "../constants";
export default {
  name: "",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      materialTypeEnum
    };
  },
  computed: {
    schemeList() {
      console.log('schemeListschemeList', this.item.schemeList )
      if (this.item.schemeList && this.item.schemeList.length > 0) {
        return this.item.schemeList.splice(0, 2);
      } else {
        let { hasMaterial, unit, price } = this.item;
        let obj = {
          name: "",
          unit: unit || "/",
          unitPrice: price || "/"
        };
        switch (+hasMaterial) {
          case 0:
            obj.name = "包含辅材";
            break;
          case 1:
            obj.name = "包含机械设备";
            break;
          case 2:
            obj.name = "包含辅材和机械设备";
            break;
          case 3:
            obj.name = "不包含辅材和机械设备";
            break;
          default:
            break;
        }
        return [obj];
      }
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
  filters: {
    /**
     * 大数值转换为万，亿函数
     * @param {Number} 大数
     * @param {Number} 保留几位小数
     */
    numConversion(num, point = 2) {
      if (!num) return "";
      const numStr = num.toString().split(".")[0]; // 去掉小数点后的数值字符串
      const numLen = numStr.length;
      if (numLen < 6) {
        return numStr;
      } else if (numLen >= 6 && numLen <= 8) {
        const decimal = numStr.substring(numLen - 4, numLen - 4 + point);
        const res = parseInt(num / 10000) + "." + decimal + "万";
        return res;
      } else if (numLen > 8) {
        const decimal = numStr.substring(numLen - 8, numLen - 8 + point);
        const res = parseInt(num / 100000000) + "." + decimal + "亿";
        return res;
      }
    },
    datanumber2datetime(datetime, fmtdata) {
      function padLeftZero(str) {
        return ("00" + str).substr(str.length);
      }
      if (datetime === "" || datetime === null) {
        return "";
      }
      const date = new Date(datetime);
      let fmt = fmtdata && fmtdata !== "" ? fmtdata : "yyyy-MM-dd hh:mm:ss";
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
      };
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    // 获取工种最后一级
    splieCategory(val) {
      if (!val) return "--";
      const categoryName = val.split("-");
      const res = categoryName[categoryName.length - 1];
      return res;
    }
  }
};
